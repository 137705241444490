import React from 'react';
import PropTypes from 'prop-types';

import { Chat, Live } from 'components';

import styled from 'styled-components';
import Frame from 'styles';

function Layout({ emote, title, showChat }) {
  return (
    <StyledWrapper>
      <Frame.Ornament />
      <StyledLive />
      {showChat && (
        <Aside>
          <Chat />
        </Aside>
      )}
      <Title>
        <img
          src={`https://static-cdn.jtvnw.net/emoticons/v1/${emote}/3.0`}
          alt=""
        />
        <strong>{title}</strong>
        Brought to you by <em>DinoVision</em>&trade;.
      </Title>
    </StyledWrapper>
  );
}

function Structure({ children }) {
  return <>{children}</>;
}

function Scene({ emote, title, showChat }) {
  return (
    <Structure>
      <Layout emote={emote} title={title} showChat={showChat} />
    </Structure>
  );
}

Layout.propTypes = {
  emote: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showChat: PropTypes.bool.isRequired
};

Structure.propTypes = {
  children: PropTypes.node.isRequired
};

Scene.propTypes = {
  emote: PropTypes.string,
  title: PropTypes.string,
  showChat: PropTypes.bool
};

Scene.defaultProps = {
  emote: null,
  title: null,
  showChat: false
};

const StyledWrapper = styled(Frame.Wrapper)`
  font-family: ${props => props.theme.fonts.freight};
  font-weight: 500;
`;

const StyledLive = styled(Live)`
  grid-column: 1;
  grid-row: 2 / span 2;
  align-items: center;
`;

const Aside = styled.aside`
  grid-column: 3;
  grid-row: 1 / span 26;
  align-content: end;
  padding: 0 60px 48px;
  width: 640px;

  background-color: ${props => props.theme.colors.muted.dark};
`;

const Title = styled.div`
  grid-column: 1 / span 2;
  grid-row: 26;
  align-self: end;

  position: relative;
  top: -24px;
  height: 112px;
  padding-left: 48px;

  color: ${props => props.theme.colors.white};
  font-size: 24px;

  img {
    position: relative;
    top: 24px;
    padding-right: 12px;
  }
  strong {
    padding-right: 12px;
    font-size: 36px;
    font-weight: 800;
  }
`;

export default Scene;

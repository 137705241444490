import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Frame from 'styles';

import { Buns, Chat, Toy } from 'components';
import logoVertical from 'components/Branding/assets/logoVertical.svg';

function Layout({ showCamera }) {
  return (
    <StyledWrapper>
      <Frame.Ornament />
      <Main>
        <Toy width={1154} auto spam />
        <Logo>
          <img src={logoVertical} alt="" />
        </Logo>
        {showCamera && (
          <Camera>
            <Buns />
          </Camera>
        )}
      </Main>
      <Aside>
        <Chat />
      </Aside>
    </StyledWrapper>
  );
}

function Structure({ children }) {
  return <>{children}</>;
}

function Scene({ showCamera }) {
  return (
    <Structure>
      <Layout showCamera={showCamera} />
    </Structure>
  );
}

Layout.propTypes = {
  showCamera: PropTypes.bool.isRequired
};

Structure.propTypes = {
  children: PropTypes.node.isRequired
};

Scene.propTypes = {
  showCamera: PropTypes.bool
};

Scene.defaultProps = {
  showCamera: false
};

const StyledWrapper = styled(Frame.Wrapper)`
  background-image: ${props =>
    `linear-gradient(270deg, ${props.theme.colors.main.green} 0%, ${props.theme.colors.muted.green} 50%)`};
  font-family: ${props => props.theme.fonts.freight};
  font-weight: 500;
`;

const Main = styled.div`
  display: flex;
  grid-column: 1 / span 2;
  grid-row: 1 / span 26;

  align-self: center;
  justify-content: center;

  img {
    margin: auto;
  }
`;

const Logo = styled.div`
  z-index: 1000;
`;

const Camera = styled.div`
  width: 612px;
  margin-left: 48px;
`;

const Aside = styled.aside`
  grid-column: 3;
  grid-row: 1 / span 26;
  align-content: end;
  padding: 0 60px 48px;
  width: 640px;

  background-color: ${props => props.theme.colors.muted.dark};
`;

export default Scene;

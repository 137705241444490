import React from 'react';

import styled from 'styled-components';

import logoVertical from 'components/Branding/assets/logoVertical.svg';

function App() {
  return (
    <Wrapper>
      <Header>
        <Logo src={logoVertical} alt="logo" />
      </Header>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  text-align: center;
`;

const Header = styled.div`
  background-color: ${props => props.theme.colors.main.green};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

const Logo = styled.img`
  height: 40vmin;
  pointer-events: none;
`;

export default App;

import React from 'react';
import PropTypes from 'prop-types';

import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import { useChatContext } from 'providers';

import Alert from './Alert';
import Message from './Message';

const spring = {
  type: 'spring',
  damping: 30,
  stiffness: 300
};

function Chat({ className }) {
  const { messages } = useChatContext();

  return (
    <Wrapper className={className}>
      <AnimatePresence>
        <Messages>
          <Welcome
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={spring}
          >
            <strong>Welcome to DinoChat&trade;</strong>
            Brought to you by Teawrex.
          </Welcome>
          {messages.map(({ isAlert, ...rest }, index) =>
            isAlert ? (
              <Alert
                key={`${rest.user}_${index}`} /* eslint-disable-line */
                tags={rest.tags}
                type={rest.type}
              />
            ) : (
              <Message
                key={`${rest.user}_${index}`} /* eslint-disable-line */
                message={rest.message}
                tags={rest.tags}
              />
            )
          )}
        </Messages>
      </AnimatePresence>
    </Wrapper>
  );
}

Chat.propTypes = {
  className: PropTypes.string
};

Chat.defaultProps = {
  className: ''
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
`;

const Welcome = styled(motion.div)`
  padding: 24px 30px;

  background: ${props => props.theme.colors.main.darkgreen};
  border-radius: 12px;
  color: ${props => props.theme.colors.main.light};

  strong {
    display: block;
    color: ${props => props.theme.colors.muted.lightgreen};
    font-size: 18px;
    font-weight: 800;
  }
`;

const Messages = styled.ol`
  padding: 0;
  margin: 0;
  width: 100%;

  font-family: ${props => props.theme.fonts.inter};
  list-style: none;
`;

export default Chat;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

function Camera({ className }) {
  return (
    <Chrome className={className}>
      <Zone />
    </Chrome>
  );
}

Camera.propTypes = {
  className: PropTypes.string
};

Camera.defaultProps = {
  className: ''
};

const Chrome = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 476px;
  overflow: hidden;
  padding-left: 6px;

  background-image: ${props =>
    `linear-gradient(270deg, ${props.theme.colors.muted.green} 0%, ${props.theme.colors.main.green} 50%)`};
  box-shadow: ${props => props.theme.shadows[2]};
`;

const Zone = styled.div`
  padding-top: ${(9 / 16) * 100}%;
  background: ${props => props.theme.colors.black};
`;

export default Camera;

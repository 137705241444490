import { addSeconds, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTrainContext } from 'providers';

import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

const variants = {
  hidden: {
    x: 200,
    transition: {
      x: { stiffness: 1000, velocity: -100 }
    }
  },
  visible: {
    x: 0,
    transition: {
      x: { stiffness: 500 }
    }
  }
};

function Train({ className }) {
  const { isTrainActive, count, timer } = useTrainContext();
  const [isVisible, setIsVisible] = useState(false);
  const [clock, setClock] = useState();

  useEffect(() => {
    setIsVisible(isTrainActive);
  }, [isTrainActive]);

  useEffect(() => {
    const helper = addSeconds(new Date(0), timer);
    setClock(format(helper, 'mm:ss'));
  }, [timer]);

  return (
    <AnimatePresence>
      {isVisible && (
        <Wrapper
          className={className}
          variants={variants}
          initial="hidden"
          animate={isVisible ? 'visible' : 'hidden'}
          exit="hidden"
        >
          <Widget>
            <Timer>{clock}</Timer>
            <Count>&times;{count}</Count>
          </Widget>
        </Wrapper>
      )}{' '}
    </AnimatePresence>
  );
}

Train.propTypes = {
  className: PropTypes.string
};

Train.defaultProps = {
  className: ''
};

const Wrapper = styled(motion.div)`
  position: relative;
`;

const Widget = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto auto;
  align-items: center;

  font-family: ${props => props.theme.fonts.freight};
  font-feature-settings: 'lnum';
  font-variant-numeric: lining-nums;
  font-weight: 600;
`;

const Count = styled.div`
  padding: 8px 10px;
  min-width: 36px;

  background-color: ${props => props.theme.colors.muted.green};
  color: ${props => props.theme.colors.white};
  font-weight: 800;
  font-feature-settings: 'tnum' 1;
  text-align: left;
`;

const Timer = styled.div`
  padding: 8px 10px;

  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  font-feature-settings: 'tnum' 1;
`;

export default Train;

import React from 'react';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';
import styled from 'styled-components';

import badgeMod from './assets/badgeMod.svg';
import * as utils from './utils';

const spring = {
  type: 'spring',
  damping: 30,
  stiffness: 300
};

function Message({ className, message, tags }) {
  return (
    <Wrapper
      className={className}
      positionTransition={spring}
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={spring}
    >
      {tags.userInfo.badges.get('bits') && (
        <CheerBadge
          src={utils.getCheerBadge(tags.userInfo.badges.get('bits'))}
          alt={tags.userInfo.badges.get('bits')}
        />
      )}
      {tags.userInfo.badges.get('subscriber') && (
        <SubscriberBadge
          src={utils.getSubscriberBadge(tags.userInfo.badges.get('subscriber'))}
          alt={tags.userInfo.badges.get('subscriber')}
        />
      )}
      {tags.userInfo.isMod && <Mod src={badgeMod} alt="" />}
      <User>{tags.userInfo.displayName}</User>
      <Content>
        <span
          dangerouslySetInnerHTML={{
            __html: utils.formatMessage(message, tags.emoteOffsets)
          }}
        />
      </Content>
    </Wrapper>
  );
}

Message.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  tags: PropTypes.shape({
    emoteOffsets: PropTypes.instanceOf(Map),
    userInfo: PropTypes.shape({
      badges: PropTypes.instanceOf(Map),
      displayName: PropTypes.string,
      isMod: PropTypes.bool,
      isSubscriber: PropTypes.bool
    })
  }).isRequired
};

Message.defaultProps = {
  className: '',
  message: ''
};

const Wrapper = styled(motion.li)`
  position: relative;
  margin-top: 12px;
  padding: 24px 64px 24px 30px;

  box-shadow: inset 0 0 0 4px ${props => props.theme.colors.main.dark};
  border-radius: 12px;
  color: ${props => props.theme.colors.main.light};
  font-size: 18px;
  line-height: 26px;
`;

const SubscriberBadge = styled.img`
  position: absolute;
  left: -18px;
  width: 36px;
  height: 36px;
`;

const CheerBadge = styled.img`
  position: absolute;
  right: -18px;
  width: 36px;
  height: 36px;
`;

const User = styled.div`
  font-weight: 700;
`;

const Content = styled.div`
  overflow: hidden;
  word-wrap: break-word;

  .emoticon {
    width: 28px;
    height: 28px;
    vertical-align: middle;
  }
`;

const Mod = styled.img`
  position: absolute;
  right: 24px;
`;

export default Message;

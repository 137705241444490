import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useEventContext } from 'providers';

import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

const list = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: {
    x: 200,
    transition: {
      x: { stiffness: 1000, velocity: -100 }
    }
  },
  visible: {
    x: 0,
    transition: {
      x: { stiffness: 500 }
    }
  }
};

function Ticker({ className }) {
  const [active, setActive] = useState(false);
  const { events } = useEventContext();

  useEffect(() => {
    setTimeout(() => setActive(true), 1000);
  }, []);

  return (
    <AnimatePresence exitBeforeEnter>
      {events && (
        <Wrapper
          initial="hidden"
          animate={active ? 'visible' : 'hidden'}
          exit="hidden"
          variants={list}
          layoutTransition
          className={className}
          onClick={() => setActive(!active)}
        >
          {events.map(event => (
            <Item variants={item} key={event.id}>
              <Actor>{event.name}</Actor>
              <Length>&times;{event.months}</Length>
            </Item>
          ))}
        </Wrapper>
      )}
    </AnimatePresence>
  );
}

Ticker.propTypes = {
  className: PropTypes.string
};

Ticker.defaultProps = {
  className: ''
};

const Wrapper = styled(motion.ol)`
  position: relative;
  align-items: center;
  overflow: hidden;
  margin: 0;
  padding: 0;

  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  list-style: none;
`;

const Item = styled(motion.li)`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2px;

  font-size: 16px;
`;

const Actor = styled.div`
  font-weight: 600;
  padding: 8px 10px;

  background-color: ${props => props.theme.colors.white};
`;

const Length = styled.div`
  padding: 8px 10px;
  width: 36px;

  background-color: ${props => props.theme.colors.main.green};
  color: ${props => props.theme.colors.white};
  font-weight: 800;
  font-feature-settings: 'tnum' 1;
`;

export default Ticker;

import styled from 'styled-components';

const Base = styled.div`
  position: absolute;
  overflow: hidden;
  width: ${props => props.theme.frame.width};
  height: ${props => props.theme.frame.height};
`;

const Wrapper = styled(Base)`
  display: grid;
  grid-template-rows: repeat(26, 30px);
  grid-template-columns: 1fr 1fr 1fr 6px;
  grid-gap: 12px 0;
  z-index: 100;
`;

const Ornament = styled.div`
  position: absolute;
  right: 0;
  width: 6px;
  height: ${props => props.theme.frame.height};
  background: ${props => props.theme.colors.main.darkgreen};
  z-index: 1000;
`;

export default {
  Base,
  Wrapper,
  Ornament
};

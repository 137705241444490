import React from 'react';
import PropTypes from 'prop-types';

import { Debug, Ticker, Train, Stream as StreamCamera } from 'components';

import styled from 'styled-components';
import Frame from 'styles';

function Layout() {
  return (
    <StyledWrapper>
      <Frame.Ornament />
      <StyledStreamCamera />
      <StyledTrain />
      <StyledTicker />
      <StyledDebug />
    </StyledWrapper>
  );
}

function Structure({ children }) {
  return <>{children}</>;
}

function Scene() {
  return (
    <Structure>
      <Layout />
    </Structure>
  );
}

Structure.propTypes = {
  children: PropTypes.node.isRequired
};

const StyledWrapper = styled(Frame.Wrapper)`
  font-family: ${props => props.theme.fonts.freight};
  font-weight: 500;
`;

const StyledTrain = styled(Train)`
  grid-column: 3 / span 2;
  grid-row: 1;
  justify-self: end;
  z-index: 1001;
`;

const StyledTicker = styled(Ticker)`
  grid-column: 3 / span 2;
  grid-row: 2 / span 25;
  justify-self: end;
  z-index: 1001;
`;

const StyledDebug = styled(Debug)`
  position: fixed;
  bottom: 0;
`;

const StyledStreamCamera = styled(StreamCamera)`
  grid-column: 3 / span 2;
  grid-row: 1 / span 26;
  align-self: center;
  justify-self: end;
  height: 268px;
  z-index: 1002;
`;

export default Scene;

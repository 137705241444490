const badgeUrl = 'https://static-cdn.jtvnw.net/badges/v1/';
const emoteUrl = 'https://static-cdn.jtvnw.net/emoticons/v1/';

export const formatMessage = (text, emotes) => {
  let splitText = Array.from(text);
  emotes.forEach((indexes, id) => {
    indexes.forEach(index => {
      let idx = index.split('-');
      idx = [parseInt(idx[0], 10), parseInt(idx[1], 10)];
      const length = idx[1] - idx[0];
      const empty = [...new Array(length + 1)].map(() => '');
      splitText = splitText
        .slice(0, idx[0])
        .concat(empty)
        .concat(splitText.slice(idx[1] + 1, splitText.length));
      splitText.splice(
        idx[0],
        1,
        `<img class="emoticon" src="${emoteUrl}${id}/3.0">`
      );
    });
  });

  return splitText.join('');
};

export const getSubscriberBadge = months =>
  ({
    0: `${badgeUrl}6a09c7f9-9d16-4a5f-8663-6410e151f00c/3`,
    3: `${badgeUrl}04edeb93-1c5b-48e1-ae05-78d5f8d14eb7/3`,
    6: `${badgeUrl}edf0e90b-2cf7-403e-8df0-c77a477b1ec9/3`,
    12: `${badgeUrl}2d65c599-ef35-42a5-aad9-dce878eef8c7/3`,
    24: `${badgeUrl}00505ba3-271b-4c35-9621-a2e8bf5f25c2/3`,
    36: `${badgeUrl}310a73a7-bbea-4358-98f0-73770d041741/3`,
    48: `${badgeUrl}346afc38-577a-4ad6-90bf-a41060eb2165/3`,
    60: `${badgeUrl}b46f27f5-eb3a-47fb-8e56-7e9f98d69a06/3`,
    72: `${badgeUrl}beb66307-02ff-4f65-9817-cecca5c29030/3`,
    84: `${badgeUrl}f719dd76-e700-47c8-9343-1ad2f02adc75/3`,
    2000: `${badgeUrl}4c203220-5235-4689-8f0d-9c0b14425d0d/3`,
    2003: `${badgeUrl}e1913d1d-444c-4b83-a1c7-d926f6be5133/3`,
    2006: `${badgeUrl}ffe49bda-7c9f-4de4-a7f7-1f2f66c1bbf3/3`,
    2012: `${badgeUrl}0a915678-8e50-469f-8671-e2914e4731b1/3`,
    2024: `${badgeUrl}d3713717-aba6-4738-a403-24d5eff6ba90/3`,
    2036: `${badgeUrl}0c20c5c5-b0fa-4485-81b4-d5a6ca23beed/3`,
    2048: `${badgeUrl}3fa2cc3c-9da8-4093-9030-42f82d4dedaa/3`,
    2060: `${badgeUrl}e1c5f60c-8a4a-48c0-86d4-2bd832529d0e/3`,
    2072: `${badgeUrl}2e536247-3744-46e5-a8b3-9ac51282ba56/3`,
    2084: `${badgeUrl}c8cfceac-8d4f-480f-b687-3d529cad70a0/3`,
    3000: `${badgeUrl}75658d52-b0e2-4b35-b6a2-512e129b5ae4/3`,
    3003: `${badgeUrl}95e53bf1-6a12-4f2f-a326-93bc68caf365/3`,
    3006: `${badgeUrl}f63eec12-ea38-422a-8afc-2debaa39ca55/3`,
    3012: `${badgeUrl}50891c92-c0d9-4a79-9d62-44c204a79e8e/3`,
    3024: `${badgeUrl}2bcad5dd-8b68-4e06-9eeb-3318d3ece9ba/3`,
    3036: `${badgeUrl}fa4ff457-71b4-4006-9b62-d629ecbc88c9/3`,
    3048: `${badgeUrl}01f45541-1f27-4ae7-b4bd-dad45fea4a72/3`,
    3060: `${badgeUrl}ebc481c9-a8dd-4947-a216-5de9a37a6de7/3`,
    3072: `${badgeUrl}11e7ffc0-bdf9-4971-a315-0be4219dd92b/3`,
    3084: `${badgeUrl}f165813f-e093-4d59-83d0-937d7e69fde7/3`
  }[months]);

export const getCheerBadge = bits =>
  ({
    1: `${badgeUrl}52f0021a-f057-423d-815f-aa2a56ca7f13/3`,
    100: `${badgeUrl}394bacf7-1b3e-4e08-8abe-ca1f017c904c/3`,
    1000: `${badgeUrl}65b2e6b5-b9f7-4f99-ad19-0f2392e10452/3`,
    5000: `${badgeUrl}8054c925-4dc4-4c2f-a178-508ceaad82e9/3`,
    10000: `${badgeUrl}1ffeb5a8-9c0f-4fd3-b734-da7e1637c355/3`,
    25000: `${badgeUrl}980f0bf4-c60a-4c35-9835-22cb2f207be3/3`,
    50000: `${badgeUrl}81ccba19-8da8-4890-adb9-0a370bbd1105/3`,
    75000: `${badgeUrl}119254e6-aedd-4f09-b340-3a34773ed9f3/3`,
    100000: `${badgeUrl}a176d1b1-df58-4bb9-b9c3-16db129908fe/3`,
    200000: `${badgeUrl}b0232856-c8eb-4fe4-a963-e9ccca1a97df/3`,
    300000: `${badgeUrl}94f4d487-53db-4014-a130-0e2232bc6a2a/3`,
    400000: `${badgeUrl}3dc013d1-3680-4973-b60b-55733e2a229a/3`,
    500000: `${badgeUrl}feea2102-844b-477f-96d0-a2238f65ba99/3`,
    600000: `${badgeUrl}ede80b62-3d2d-4e2c-9bc7-3fd58ae131d4/3`,
    700000: `${badgeUrl}f913cd47-544e-4d9e-a52d-51e117146247/3`,
    800000: `${badgeUrl}71ae1c2b-b3e1-415b-b203-45788cccc518/3`,
    900000: `${badgeUrl}6170317a-364e-4ae7-94d2-4788387bd2bc/3`,
    1000000: `${badgeUrl}4c11f405-8cb1-40d5-b985-4360b2e11b75/3`
  }[bits]);

import { createGlobalStyle } from 'styled-components';
import { normalize, rgba } from 'polished';

import colors from './colors';

export const foundation = {
  colors,
  fonts: {
    inter: "'Inter UI', sans-serif"
  },
  easing: 'cubic-bezier(.62, .28, .23, .99)',
  frame: {
    width: '1920px',
    height: '1080px'
  },
  shadows: [
    `0 1px 3px ${rgba(colors.black, 0.12)}, 0 1px 2px ${rgba(
      colors.black,
      0.24
    )}`,
    `0 3px 6px ${rgba(colors.black, 0.16)}, 0 3px 6px ${rgba(
      colors.black,
      0.23
    )}`,
    `0 10px 20px ${rgba(colors.black, 0.19)}, 0 6px 6px ${rgba(
      colors.black,
      0.23
    )}`,
    `0 14px 28px ${rgba(colors.black, 0.25)}, 0 10px 10px ${rgba(
      colors.black,
      0.22
    )}`,
    `0 19px 38px ${rgba(colors.black, 0.3)}, 0 15px 12px ${rgba(
      colors.black,
      0.22
    )}`
  ]
};

const BaseStyles = createGlobalStyle`
  ${normalize()}

  @import url('https://rsms.me/inter/inter.css');
  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
`;

export default BaseStyles;

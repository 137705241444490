import React from 'react';
import PropTypes from 'prop-types';

import { Stream as StreamCamera } from 'components';

import styled from 'styled-components';

function Layout() {
  return <StyledStreamCamera />;
}

function Structure({ children }) {
  return <>{children}</>;
}

function Scene() {
  return (
    <Structure>
      <Layout />
    </Structure>
  );
}

Structure.propTypes = {
  children: PropTypes.node.isRequired
};

const StyledStreamCamera = styled(StreamCamera)`
  margin: 24px 0 24px 24px;
  height: 268px;
  z-index: 1002;
`;

export default Scene;

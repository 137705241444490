export default {
  main: {
    dark: '#38602D',
    darkgreen: '#5a9a49',
    gray: '#4a4a4a',
    green: '#7bd263',
    light: '#daf3d3',
    lightgreen: '#cfefc5'
  },
  muted: {
    green: '#70c059',
    dark: '#162712'
  },
  black: '#000000',
  white: '#ffffff'
};

import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { ThemeProvider } from 'styled-components';

import {
  useChatContext,
  useMessageContext,
  useEventContext,
  useNotificationContext,
  useTrainContext
} from 'providers';
import App from 'scenes/App';
import Activity from 'scenes/Activity';
import { Camera, Widget } from 'scenes/Camera';
import Dashboard from 'scenes/Dashboard';
import Start from 'scenes/Start';
import { foundation } from 'styles/foundation';

const history = createBrowserHistory();

const Contexts = () => (
  <useNotificationContext.Provider>
    <useEventContext.Provider>
      <useTrainContext.Provider>
        <useMessageContext.Provider>
          <useChatContext.Provider>
            <Main />
          </useChatContext.Provider>
        </useMessageContext.Provider>
      </useTrainContext.Provider>
    </useEventContext.Provider>
  </useNotificationContext.Provider>
);

const Main = () => (
  <Switch>
    <Route exact path="/scenes/activity" component={Activity} />
    <Route exact path="/scenes/camera/widget" component={Widget} />
    <Route
      exact
      path="/scenes/camera/front"
      render={() => <Camera emote="950098" title="DinoTalk." />}
    />
    <Route
      exact
      path="/scenes/camera/front/chat"
      render={() => <Camera emote="950098" title="DinoTalk." showChat />}
    />
    <Route
      exact
      path="/scenes/camera/left"
      render={() => <Camera emote="180743" title="The Left Camera." />}
    />
    <Route
      exact
      path="/scenes/camera/right"
      render={() => <Camera emote="1307602" title="The Right Camera." />}
    />
    <Route exact path="/scenes/start" component={Start} />
    <Route
      exact
      path="/scenes/start/buns"
      render={() => <Start showCamera />}
    />
    <Route exact path="/dashboard" component={Dashboard} />
    <Route component={App} />
  </Switch>
);

function Root() {
  return (
    <Router history={history}>
      <ThemeProvider theme={foundation}>
        <Contexts />
      </ThemeProvider>
    </Router>
  );
}

export default Root;

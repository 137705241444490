import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import styled from 'styled-components';

import logoCircle from 'components/Branding/assets/logoCircle.svg';

const baseUrl = 'https://dino.synthform.tv/scenes';
const scenes = [
  {
    title: 'Now Playing Widget (1920x60)',
    externalUrl:
      'https://teawrex.ybot.me/graphics/teawrex-nowplaying/index.html?key=27e08922-15bf-426a-942a-a935f9522195'
  },
  {
    title: 'Notifier',
    externalUrl:
      'https://teawrex.ybot.me/graphics/teawrex-notify/index.html?key=27e08922-15bf-426a-942a-a935f9522195'
  },
  {
    title: 'Starting Screen',
    location: '/start/'
  },
  {
    title: 'Starting Screen (with BunsCam)',
    location: '/start/buns/'
  },
  {
    title: 'Activity View',
    location: '/activity/'
  },
  {
    title: 'Camera Widget (506x268)',
    location: '/camera/widget/'
  },
  {
    title: 'Camera View: Front',
    location: '/camera/front/'
  },
  {
    title: 'Camera View: Front (with Chat)',
    location: '/camera/front/chat/'
  },
  {
    title: 'Camera View: Left',
    location: '/camera/left/'
  },
  {
    title: 'Camera View: Right',
    location: '/camera/right/'
  }
];
const tips = `All of the following scenes run through a **1920x1080** browser source. Apart from your song and sub notifiers, these are all-inclusive scenes for each view you'd like to have.

**Some things to note:**

- Size of BunsCam: _612px&times;344px_
- Size of streaming camera on the activity view: _476px&times;268px_
`;

const Location = ({ url }) => {
  return <Input type="text" readOnly value={url} />;
};

function Dashboard() {
  return (
    <Wrapper>
      <Logo src={logoCircle} alt="Logo" />
      <Container>
        <Tips>
          <ReactMarkdown source={tips} />
        </Tips>
        <Scenes>
          {scenes.map(scene => (
            <Scene key={scene.title}>
              <Title>{scene.title}</Title>
              <Location
                url={
                  scene.externalUrl
                    ? `${scene.externalUrl}`
                    : `${baseUrl}${scene.location}`
                }
              />
            </Scene>
          ))}
        </Scenes>
      </Container>
    </Wrapper>
  );
}

Location.propTypes = {
  url: PropTypes.string.isRequired
};

const Wrapper = styled.div`
  height: 100%;
  width: 100vw;
  padding: 36px;

  background: ${props => props.theme.colors.main.darkgreen};
`;

const Logo = styled.img`
  margin-bottom: 36px;
`;

const Container = styled.div`
  min-width: 640px;
  max-width: 30vw;
`;

const Tips = styled.div`
  padding: 12px 24px;
  width: calc(100% - 24px);

  border-radius: 6px;
  box-shadow: inset 0 0 0 1px ${props => props.theme.colors.main.green};
  color: ${props => props.theme.colors.main.light};
  font-size: 14px;

  ul {
    padding-left: 24px;
  }
`;

const Scenes = styled.ul`
  padding: 0;
  margin-bottom: 36px;

  list-style: none;
`;

const Scene = styled.li`
  margin-bottom: 24px;
`;

const Title = styled.label`
  display: block;
  padding: 12px 0;

  color: ${props => props.theme.colors.main.light};
  font-weight: 700;
`;

const Input = styled.input`
  display: block;
  padding: 12px;
  width: 100%;

  border-radius: 6px;
  border: none;
  box-shadow: inset 0 0 0 1px ${props => props.theme.colors.main.green};
  color: ${props => props.theme.colors.main.gray};
  font-size: 14px;
  font-weight: 600;
`;

export default Dashboard;

import React from 'react';
import PropTypes from 'prop-types';

import { motion } from 'framer-motion';
import styled from 'styled-components';

const spring = {
  type: 'spring',
  damping: 30,
  stiffness: 300
};

const getTier = plan =>
  ({
    Prime: 'with Twitch Prime',
    1000: 'Tier 1',
    2000: 'Tier 2',
    3000: 'Tier 3'
  }[plan]);

const Resub = ({ tags }) => (
  <Content>
    <User>{tags.displayName}</User>
    {' resubscribed for '}
    {tags.months}
    {' months '}
    {tags.plan !== 'Prime' ? 'at ' : ''}
    <Tier>{getTier(tags.plan)}!</Tier>
  </Content>
);

const SubGift = ({ tags }) => (
  <Content>
    <User>{tags.displayName}</User>
    {' was gifted a '}
    <Tier>{getTier(tags.plan)}</Tier>
    {' subscription by '}
    <User>{tags.gifterDisplayName}!</User>
  </Content>
);

const Subscription = ({ tags }) => (
  <Content>
    <User>{tags.displayName}</User>
    {' subscribed at '}
    <Tier>{getTier(tags.plan)}!</Tier>
  </Content>
);

const getContent = (event, tags) =>
  ({
    resub: <Resub tags={tags} />,
    subgift: <SubGift tags={tags} />,
    subscription: <Subscription tags={tags} />
  }[event]);

const getEmote = event =>
  ({
    resub: 'https://static-cdn.jtvnw.net/emoticons/v1/950114/3.0',
    subgift: 'https://static-cdn.jtvnw.net/emoticons/v1/590566/3.0',
    subscription: 'https://static-cdn.jtvnw.net/emoticons/v1/1721368/3.0'
  }[event]);

function Alert({ className, tags, type }) {
  return (
    <Wrapper
      className={className}
      positionTransition={spring}
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={spring}
    >
      <Emote src={getEmote(type)} />
      {getContent(type, tags)}
    </Wrapper>
  );
}

Alert.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.shape({
    displayName: PropTypes.string,
    message: PropTypes.string,
    isPrime: PropTypes.bool,
    months: PropTypes.number,
    plan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // Gift specific.
    count: PropTypes.number,
    gifterDisplayName: PropTypes.string
  }).isRequired,
  type: PropTypes.string.isRequired
};

Alert.defaultProps = {
  className: ''
};

const Wrapper = styled(motion.li)`
  position: relative;
  margin-top: 12px;
  padding: 24px 64px 24px 30px;
  display: flex;
  align-items: center;

  border-radius: 12px;
  color: ${props => props.theme.colors.main.light};
  font-size: 18px;
  line-height: 26px;
`;

const Emote = styled.img`
  margin-right: 18px;
  width: 56px;
  height: 56px;
`;

const User = styled.div`
  display: inline-block;
  font-weight: 700;
  color: ${props => props.theme.colors.white};
`;

const Content = styled.div`
  display: inline-block;
  padding-left: 4px;
`;

const Tier = styled.div`
  display: inline-block;
  font-weight: 700;
`;

export default Alert;

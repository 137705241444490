import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

function Camera({ className }) {
  return (
    <Chrome className={className}>
      <Header>
        <TeaBuns
          src="https://static-cdn.jtvnw.net/emoticons/v1/950096/3.0"
          alt="teaBuns"
        />
        <TeaLurk
          src="https://static-cdn.jtvnw.net/emoticons/v1/590566/3.0"
          alt="teaLurk"
        />
        BunsCam&trade;
      </Header>
      <Zone />
    </Chrome>
  );
}

Camera.propTypes = {
  className: PropTypes.string
};

Camera.defaultProps = {
  className: ''
};

const Chrome = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${props => (props.explicitHeight ? '100%' : 'auto')};

  box-shadow: ${props => props.theme.shadows[2]};
`;

const Header = styled.div`
  position: absolute;
  top: -56px;
  display: flex;
  height: 56px;
  align-items: center;
  overflow: hidden;
  padding: 0 24px;

  color: ${props => props.theme.colors.white};
  font-size: 24px;
  font-weight: 800;
`;

const TeaBuns = styled.img`
  width: 56px;
  height: 56px;
`;

const TeaLurk = styled.img`
  position: relative;
  top: 6px;
  left: -16px;
  width: 56px;
  height: 56px;
  z-index: -1;
`;

const Zone = styled.div`
  padding-top: ${(9 / 16) * 100}%;
  background: ${props => props.theme.colors.black};
`;

export default Camera;

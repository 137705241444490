import { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import createUseContext from 'constate';

import firestore from 'firestore';

function useEvents() {
  const [events, setEvents] = useState([]);
  const [value, loading] = useCollection(
    firestore
      .firestore()
      .collection('broadcasters')
      .doc('teawrex')
      .collection('events')
      .where('bucket', '==', 'subscription')
      .orderBy('timestamp', 'desc')
      .limit(5)
  );

  useEffect(() => {
    if (!loading) {
      setEvents(value.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }
  }, [loading, value]);

  return { events };
}

const useEventContext = createUseContext(useEvents);

export default useEventContext;

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import logoCircle from './Branding/assets/logoCircle.svg';

function Live({ className }) {
  return (
    <Wrapper className={className}>
      <img src={logoCircle} alt="logoCircle" />
      <Text>LIVE</Text>
      <Circle />
    </Wrapper>
  );
}

Live.propTypes = {
  className: PropTypes.string
};

Live.defaultProps = {
  className: ''
};

const Wrapper = styled.div`
  display: flex;
  margin-left: 48px;

  color: ${props => props.theme.colors.white};
  font-size: 33px;
  font-weight: 900;
`;

const Text = styled.div`
  margin: 0 6px 0 14px;
`;

const Circle = styled.div`
  background-color: #d0021b;
  height: 26px;
  width: 26px;
  border-radius: 16px;
`;

export default Live;
